//
// print.scss
//

// Used invoice page
@media print {
	hr,
	.left-side-menu,
	.right-bar,
	.page-title-box,
	.navbar-custom,
	.footer,
	.print-hide,
	.action,
	.topnav,
	.navbar-custom {
		display: none;
	}
	.card-body,
	.content-page,
	.right-bar,
	.content,
	body {
		padding: 0;
		margin: 0;
	}
	.card,
	.item-print {
		border: none !important;
		padding: 0px !important;
		margin-bottom: 0px !important;
	}
	.print-show {
		border: none !important;
		width: 100% !important;
	}
	.table-responsive {
		overflow-x: auto !important;
	}
	.printf-form-table {
		.trackingAmount {
			padding: 6px !important;
			width: 5% !important;
			white-space: normal !important;
			min-width: 10px !important;
			text-align: center;
		}
		.weight,
		.trackingShippingCost,
		.trackingShippingFee,
		.trackingOtherFee,
		.trackingSurcharge,
		.trackingDeliveryFee,
		.trackingTotalMoney {
			padding: 6px !important;
			min-width: 10px !important;
			width: 7% !important;
			white-space: normal !important;
			text-align: center;
		}
		.orderId,
		.code,
		.productName,
		.createdAt {
			padding: 6px !important;
			width: 12% !important;
			min-width: 10px !important;
			white-space: normal !important;
			text-align: center;
		}
		.action {
			display: none;
		}
		.data-table-text {
			padding: 6px !important;
			white-space: normal !important;
			text-align: center;
			word-break: break-all;
		}
	}
	.card-body,
	thead {
		border: 1px solid rgb(226 231 241);
	}
	.print-value-form {
		width: 100% !important;
		padding-bottom: 6px !important;
		span,
		p {
			margin: 0px !important;
		}
	}
	.box-custom {
		border: none !important;
		padding: 0px;
	}
	.table-tracking-print {
		margin-top: 0.5rem !important;
		margin-bottom: 1rem !important;
	}
	.money-print {
		margin-bottom: 0.5rem;
	}
	.signature-print {
		margin-top: 0.5rem !important;
	}
	.info-customer-create-bill {
		margin-left: 1rem;
	}
	.data-table-text {
		font-size: 14px;
	}
}
