//
// general.scss
//

html {
	position: relative;
	min-height: 100%;
}

body {
	overflow-x: hidden;
}

.text-break-all {
	word-break: break-all !important;
}
